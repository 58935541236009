<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            个人信息
        </div>
        <div class="vip-content-inner">
            <div class="login-register-form inline">
                <el-form label-position="top" ref="userform" :model="userform" :rules="userRules">
                    <el-form-item label="真实姓名：" prop="name">
                        <div class="con">
                            <el-input v-model="userform.name" placeholder="真实姓名"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册单位：" prop="companyName">
                        <div class="con">
                            <el-input v-model="userform.companyName" placeholder="请输入注册单位" :disabled="iscompanyName"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="手机号：" prop="phone">
                        <div class="con">
                            <el-input v-model="userform.phone" placeholder="请输入手机号" :disabled="isPhone"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="固定电话：">
                        <div class="con">
                            <el-input v-model="userform.landlineTelephone" placeholder="请输入固定电话"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="邮箱：" prop="email">
                        <div class="con">
                            <el-input v-model="userform.email" placeholder="请输入邮箱" :disabled="isEmail"></el-input>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="btn-row">
                    <div class="g-btn small tac" @click="updateUserinfo">
                        <img src="@/assets/img/i-save.png" alt="">
                        保存
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {userDetail,updateUserDetail} from "@/api/account";
import Bus from "@/utils/bus";

export default {
    name: "personalInfo",
    data() {
        return {
            userform: {
				name: '',
				companyName: '',
				phone: '',
				landlineTelephone: '',
				email: '',
				// userId:JSON.parse(localStorage.getItem('userInfo')).userId
            },
			userRules: {
				name: [{required: true, trigger: 'blur', message: '必填项'}],
				companyName: [{required: true, trigger: 'blur', message: '必填项'}],
				phone: [{required: true, trigger: 'blur', message: '必填项'}],
				email: [{required: true, trigger: 'blur', message: '必填项'}],
			},

            isImproveinfo:false, // 是否来完善信息
            iscompanyName:"", // 是否有单位名称
            isPhone:"", // 是否有手机号
            isEmail:"", // 是否有邮箱

        }
    },
    mounted() {
        console.log(this.$route.query.type=='完善信息')
        if(this.$route.query.type=='完善信息'){
            this.isImproveinfo=true
        }
    	// 有token并且未过期
		if(localStorage.getItem('Token') && !this.$utils.isTokenExpired()){
			this.getuserDetail() // 查询用户信息
		}else{
			this.$router.push({path:'/home'})
		}
    },
    methods:{


        //  -------------------------接口请求
        getuserDetail(){ // 获取用户信息
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId:userId}).then((res) => {
                if (res.data.code==200) {
                	let data=res.data.data
                    this.iscompanyName=data.companyName ?true:false
                    this.isPhone=data.phone ?true:false
                    this.isEmail=data.email ?true:false

                    this.userform=data
                }

            })
        },
		updateUserinfo(){ // 修改用户信息
			this.$refs.userform.validate(valid => {
				if (valid) {
					if(!(/^1[3456789]\d{9}$/.test(this.userform.phone))){
						this.$message({
							type: 'warning',
							message: '手机号格式不正确！',
							duration: 1500
						});
						return
					}
					let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
					if(this.userform.email!=''&&!(reg.test(this.userform.email))){
						this.$message({
							type: 'warning',
							message: '邮箱格式不正确！',
							duration: 1500
						});
						return
					}
					updateUserDetail(this.userform).then((res) => {
						if (res.data.code==200) {
							this.$message({
								type: 'success',
								message: "修改成功",
								duration: 2000
							});
                            if(this.isImproveinfo){ // 完善信息后返回上一级页面
                                setTimeout(()=>{
                                    this.$router.go(-1)
                                },800)
                            }
						}else{
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 2000
							});
						}

					})
				}
			})

		},
    },
}
</script>

<style scoped>

</style>
